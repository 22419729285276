<template>
  <div class="Resident">
    <div class="Resident-tit">
      <img src="../../assets/images/position/zctit.png" />
    </div>
    <div class="Resident-yunimg">
      <img src="../../assets/images/position/nodata.png" />
    </div>
    <div class="Resident-introduce">
      <div class="ri-tit font22">
        <span>{{ assignInfo.name }}</span>
      </div>
      <div class="introduce-content" v-html="assignInfo.remark">
        <!-- {{item.remark}} -->
      </div>
    </div>
    <div class="Resident-introduce">
      <div class="ri-tit font22">
        <span>{{ assignDemand.name }}</span>
      </div>
      <div class="introduce-content" v-html="assignDemand.remark">
        <!-- {{item.remark}} -->
      </div>
    </div>
    <!-- <div class="Resident-introduce">
			<div class="ri-tit font22">
				<span>驻场用人要求</span>
			</div>
			<div class="introduce-content">
				驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者驻场用人需要求职者
			</div>
		</div> -->
    <div class="apply_tishi">
      <div class="p-img font16">
        <i class="el-icon-warning"></i>
      </div>
      <div class="p-cont font14" style="color: #666666">
        我们将在短时间内与您取得联系，请您注意来电，您也可主动联系平台客服
        电话：0571-85109006
      </div>
    </div>
    <div class="apply-btn">
      <el-button type="primary" @click="applyDialogVisible = true"
        >申请驻场</el-button
      >
    </div>
    <!-- 申请驻场招聘弹窗 -->
    <el-dialog
      :visible.sync="applyDialogVisible"
      width="650px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="apply-box-out"
    >
      <div class="apply-box">
        <span class="closeicon" @click="closeapplyDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font20">申请驻场招聘</div>
        <div class="frombox">
          <el-input
            v-model="userphone"
            placeholder="输入您的联系方式"
          ></el-input>
          <div class="apply_tishi">
            <div class="p-img font16">
              <i class="el-icon-warning"></i>
            </div>
            <div class="p-cont font14">
              我们将在短时间内与您取得联系，请您注意来电，您也可主动联系平台客服
              电话：0571-85109006
            </div>
          </div>
          <div class="upapply-btn">
            <el-button type="primary" @click="submit">提交申请</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 入驻招聘信息尚未完善 -->
    <el-dialog
      :visible.sync="finishDialogVisible"
      width="600px"
      center
      class="finish_box"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="finish_box-in">
        <div class="db-img">
          <img src="../../assets/images/position/nowanshan.png" />
        </div>

        <div class="db-ts font18">
          发布驻场需先设置考勤信息，完善之后就可以发布入驻职位了
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="finishDialogVisible = false">取 消</el-button>
          <!-- toaddsettled() -->
          <el-button type="primary" @click="wanshan">立即完善</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 开通入驻权限 -->
    <el-dialog
      :visible.sync="jurisdictionDialogVisible"
      width="600px"
      center
      class="finish_box"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="finish_box-in">
        <div class="db-img">
          <img src="../../assets/images/position/noquanxian.png" />
        </div>

        <div class="db-ts font18">请先开通入驻权限</div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jurisdictionDialogVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="jurisdictionDialogVisible = false"
            >立即开通</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      applyDialogVisible: false,
      userphone: "",
      finishDialogVisible: false,
      jurisdictionDialogVisible: false,
      assignInfo: [],
      assignDemand: [],
    };
  },
  activated() {
    this.getAssignInfo();
    this.getAssignDemand();
    this.$api.checkSave("post").then((res) => {
      // console.log(res.data);
      /// 职级未设置 10802 考勤未设置10801
      if (res.code === 10801 || res.code === 10802) {
        this.finishDialogVisible = true;
      }
    });
  },
  methods: {
    wanshan() {
      this.finishDialogVisible = false;
      this.$router.push({
        path: "worktime",
      });
    },
    getAssignInfo() {
      this.$api.getdict("get", { code: "assign_info" }).then((res) => {
        this.assignInfo = res.data[0];
        this.assignDemand = res.data[1];
      });
      // this.$api.getdict("get", { code: "employing_info" }).then((res) => {
      //   this.assignInfo = res.data;
      // });
    },
    getAssignDemand() {
      // this.$api.getdict("get", { code: "employing_demand" }).then((res) => {
      //   this.assignDemand = res.data;
      // });
    },
    closeapplyDialogbox() {
      this.applyDialogVisible = false;
    },
    toaddsettled() {
      this.$router.push("/addsettled");
    },
    submit() {
      if (this.userphone) {
        this.$api.applyTemp("post", { phone: this.userphone }).then(() => {
          this.$message({
            message: "提交成功",
            type: "success",
          });
          this.applyDialogVisible = false;
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
  },
};
</script>
<style type="text/css">
.el-dialog__header,
.el-dialog__body {
  padding: 0 !important;
}
</style>
<style lang="less" scoped>
/deep/ .el-button {
  border-radius: 0px;
}
.Resident {
  // height: 100%;
  background-color: #ffffff;
  padding: 0 150px 180px 150px;
  .Resident-tit {
    text-align: center;
    padding: 89px 0;
    img {
      width: 218px;
      height: 80px;
    }
  }
  .Resident-yunimg {
    text-align: center;
    img {
      width: 600px;
      height: 366px;
    }
  }
  .Resident-introduce {
    margin-top: 100px;
    // height: 200px;
    background: url("../../assets/images/position/text-box.png") no-repeat left
      top;
    background-size: 100% 100%;
    padding: 0 0 0 0;
    .ri-tit {
      margin: 0 auto;
      width: 240px;
      height: 45px;
      background: url("../../assets/images/position/tb-tit.png") no-repeat left
        top;
      background-size: 100% 100%;
      color: #ffffff;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .introduce-content {
      line-height: 1.5;
      color: #333333;
      padding: 50px 50px;
      min-height: 160px;
      word-wrap: break-word;
    }
  }
  .apply-btn {
    padding: 0 20px;
    margin-top: 60px;
    button {
      width: 100%;
      height: 54px;
      border: none;
      background-color: #ffb400;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2px;
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.apply-box-out {
  .apply-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      text-align: center;
      padding: 58px 100px 30px 100px;

      .upapply-btn {
        margin-top: 60px;
        button {
          width: 100%;
          height: 40px;
          background-color: #00bcff;
          border: none;
        }
      }
    }
  }
}

.apply_tishi {
  display: flex;
  margin-top: 10px;
  .p-img {
    margin: 0 8px;
    color: #ff4444;
  }
  .p-cont {
    display: flex;
    flex: 1;
    color: #666666;
    line-height: 1.5;
    text-align: left;
  }
}
.finish_box {
  // line-height: 4;
  .finish_box-in {
    padding: 36px 0 30px 0;
    .db-img {
      text-align: center;
      img {
        width: 208px;
        height: 180px;
      }
    }

    .db-ts {
      text-align: center;
      color: #111111;
      // line-height: 1.5;
      font-weight: 600;
    }
    .dialog-footer {
      text-align: center;
      margin: 0 auto;
      /deep/ .el-input__inner {
        border-radius: 0px;
      }
      /deep/ .el-button {
        border-radius: 0px;
      }
      button {
        width: 200px;
        height: 40px;
        margin-top: 70px;
      }
    }
  }
}
</style>
